import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// dummy image
import dummyImage from '../../assets/no_image.jpg';

const NetworkImage = (props) => {
  const { imageProps, url, placeHolder, size, width } = props;

  const [error, setError] = useState(false);
  const [imgSrc, setImgSrc] = useState(url);

  const handleImageError = (e) => {
    setError(true); // Cambia el estado a error
    e.target.onerror = null; // Evita bucles infinitos
    e.target.src = dummyImage; // Establece una imagen de error alternativa
  };

  useEffect(() => {
    // Crear un timestamp que cambie cada hora
    const hourlyTimestamp = Math.floor(Date.now() / (1000 * 60 * 60));
    const cachedUrl = `${error ? dummyImage : url}?cache=${hourlyTimestamp}`;
    setImgSrc(cachedUrl); // Actualiza la URL de la imagen con el timestamp
  }, []);

  // Si la URL es una cadena vacía, mostrar el placeholder
  return (
    <>
      <div className='NetworkImage' {...imageProps}
        style={{
          width: size || width || '2rem',
          height: size || width || '2rem',
          backgroundImage: `url(${ imgSrc })`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <img
          style={{
            width: size || width || '100%',
            height: size || width || '100%',
            objectFit: 'contain', 
            display: error ? 'block' : 'none', // Oculta la imagen si hay un error
          }}
          src={imgSrc}
          alt="network-image"
          onError={handleImageError}
        />
      </div>
      {/* {error ? (
        <img src={errorImage} alt="error-image" {...imageProps} />
      ) : (
        <img
          src={url}
          alt="network-image"
          onError={handleImageError}
          {...imageProps}
        />
      )} */}
      {!url && placeHolder}
    </>
  );
};

NetworkImage.defaultProps = {
  imageProps: {},
  placeHolder: <img src={dummyImage} alt="Loading" />
};

NetworkImage.propTypes = {
  imageProps: PropTypes.object,
  url: PropTypes.string.isRequired,
  placeHolder: PropTypes.node
}

NetworkImage.defaultProps = {
  imageProps: {},
  placeHolder: <img src={dummyImage} alt='Loading' />
}

export default NetworkImage
