import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
// components
import ProductListMarket from '../../../components/ProductListMarket/ProductListMarket'
import MercadoCard from '../../../components/MercadoCard/MercadoCard'
import CustomButton from '../../../components/atoms/buttons/CustomButton'
import CreateMarketForm from '../../../components/CreateMarketForm/CreateMarketForm'
import CircularLoader from '../../../components/loaders/CircularLoader'
import MarketSummary from '../../../components/Summaries/MarketSummary'
// modals

import AddressValidationModal from '../../../components/Modals/AddressValidationModal'
import DeleteMarketModal from '../../../components/MercadoCard/DeleteMarketModal'
// hooks
import useMarkets from '../../../hooks/markets/useMarkets'
import useAuth from '../../../hooks/auth/useAuth'

const MercadosDesktop = () => {
	// hooks
	const {
		getCityStorage,
		getCityBranchOffice,
		authValues,
		showLoginModal
	} = useAuth()
	const city = getCityStorage()
	const branchOffice = getCityBranchOffice()

	useEffect(() => {
		if (!city) showLoginModal()
	}, [])

	const addresses = authValues.addresses
	const haveAddresses = addresses.length > 0

	const { markets, deleteMarket, getUserMarkets } = useMarkets()

	// state
	const [selectedMarket, setSelectedMarket] = useState(null)
	const [marketToEdit, setMarketToEdit] = useState(null)
	const [marketToDelete, setMarketToDelete] = useState('')

	// delete modal control variables
	const [openDM, setOpenDM] = useState(false)
	// addresses validation modal control variables
	const [openAV, setOpenAV] = useState(false)
	// add product modal control variables
	// eslint-disable-next-line no-unused-vars
	const [openAddProduct, setOpenAddProduct] = useState(false)

	// markets views and actions confirmation

	const selectLastCreated = () => {
		const lastCreated = markets.reduce(
			(previous, current) =>
				previous.created_date > current.created_date ? previous : current,
			''
		)
		setSelectedMarket(lastCreated)
	}

	const checkIfSelected = (marketId) => {
		return selectedMarket
			? selectedMarket.id === marketId
			: marketToEdit
				? marketToEdit.id === marketId
				: false
	}

	const prepareToEditMarket = (market) => (e) => {
		e.stopPropagation()

		if (!haveAddresses) {
			setOpenAV(true)
			return null
		}
		let marketToEdit = { ...market }
		delete marketToEdit.product
		setMarketToEdit(marketToEdit)
		setSelectedMarket(null)
	}

	const prepareToCreateMarket = () => {
		if (!haveAddresses) {
			setOpenAV(true)
			return null
		}
		setMarketToEdit(null)
		setSelectedMarket(null)
	}

	const confirmationToDelete = (marketId) => (e) => {
		e.stopPropagation()
		setOpenDM(true)
		setMarketToDelete(marketId)
	}

	// data actions
	const handleDelete = () => {
		if (marketToDelete) {
			setOpenDM(false)
			deleteMarket(marketToDelete)
		}
	}

	useEffect(() => {
		getUserMarkets()
	}, [authValues])

	useEffect(() => {
		// console.log('mercados', markets)
		if (!markets) return
		selectLastCreated()
	}, [markets])

	return (
		<>
			{!markets || !city ? (
				<CircularLoader />
			) : (
				<div className='mercados-desktop'>
					<span className='color-blue text-21px-700 section-title'>
						Mis mercados
					</span>
					<Grid container spacing={2} className='principal-container'>
						<Grid item xs={12} lg={6} className='list-container'>
							<div className='scrollable-list custom-scrollbar'>
								{markets.length > 0 ? (
									markets.map((market) => (
										<MercadoCard
											key={market.id}
											selected={checkIfSelected(market.id)}
											marketId={market.id}
											address={market.address}
											name={market.name}
											products={market.products}
											city={city}
											branchOffice={branchOffice}
											onDelete={confirmationToDelete(market.id)}
											onEdit={prepareToEditMarket(market)}
											onClick={() => {
												setSelectedMarket(market)
											}}
										/>
									))
								) : haveAddresses ? (
									<span className='text-16px-400 color-gray'>
										No has creado mercados aún
									</span>
								) : (
									<span className='text-16px-400 color-gray'>
										Registra al menos una dirección para crear mercados
									</span>
								)}
							</div>
							{selectedMarket && (
								<CustomButton
									label='Crear nuevo mercado'
									width='100%'
									onClick={prepareToCreateMarket}
								/>
							)}
							<DeleteMarketModal
								open={openDM}
								handleClose={() => setOpenDM(false)}
								onDelete={handleDelete}
							/>
							<AddressValidationModal
								open={openAV}
								handleClose={() => setOpenAV(false)}
								actionToValidate='crear o editar un mercado'
							/>
						</Grid>

						<Grid item xs={12} lg={6} className='list-container'>
							{selectedMarket ? (
								//only if a market is selected
								<>
									<div className='scrollable-list custom-scrollbar'>
										<ProductListMarket
											city={city}
											branchOffice={branchOffice}
											title='Productos'
											marketId={selectedMarket.id}
											resumeLabel='Productos'
										/>
										<MarketSummary
											marketId={selectedMarket.id}
											city={city}
											branchOffice={branchOffice}
											addProduct={() => setOpenAddProduct(true)}
										/>
									</div>
									{/* <AddProductToMarketModal
                                                marketId={selectedMarket.id}
                                                open={openAddProduct}
                                                handleClose={() => setOpenAddProduct(false)}
                                            />
                                            <CustomButton
                                                label="Agregar productos"
                                                variant="outlined"
                                                width="100%"
                                                onClick={() => setOpenAddProduct(true)}
                                            /> */}
								</>
							) : (
								haveAddresses && <CreateMarketForm market={marketToEdit} />
							)}
						</Grid>
					</Grid>
				</div>
			)}
		</>
	)
}

export default MercadosDesktop
